import * as React from "react"
import { Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import "../styles.css";
import NavBar from "../components/navbar";
import BottomBar from "../components/bottomBar";

const RefundsPolicy = () => {

    const isBrowser = () => typeof window !== "undefined";
  
    return (
      <PageLayout>
            <TopNavigation
                testId="topNavigation"
                id="product-navigation"
                skipLinkTitle="Product Navigation"
                height={60}
                isFixed={false}
            >
                <NavBar/>
            </TopNavigation>
          <Content testId="content">
          <div style={{ width: "100vw", margin: "auto", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div style={{ maxWidth: "75%", paddingBottom: 100 }}>
            <h1>Refunds Policy</h1>
            <p>Last updated: February 02, 2023</p>
            <hr/>
            <p>Thank you for subscribing to Autocompress for Bitbucket!</p>
            <p>If, for any reason, you are not completely satisfied with the purchase please feel free to review our policy on refunds or to contact with us directly. Hold My Coffee is dedicated to free developer's time, this includes solving payment problems fast!</p>
            <p>The following terms are applicable for any products that you purchased on the Autocompress website. Not necessarilly applicable to other products from Hold My Coffee, please review their corresponding policies.</p>
            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural. Forgive the legal jargon!</p>
            <h2>Definitions</h2>
            <p>For the purposes of this Refund Policy:</p>
            <ul>
            <li>
            <p><strong>Application</strong> means the software program (Autocompress) provided by the Company and installed by you on an instance of Bitbucket, be it a shared cloud instance or a private server.</p>
            </li>
            <li>
            <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Hold My Coffee, 33 The Sycamores CB246XJ Cambridge.</p>
            </li>
            <li>
            <p><strong>Goods</strong> refer to the items offered for sale on the Service. Also known as product features.</p>
            </li>
            <li>
            <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
            </li>
            <li>
            <p><strong>Service</strong> refers to the Application or the Website or both.</p>
            </li>
            <li>
            <p><strong>Website</strong> refers to Autocompress, accessible from <a href="a-compress.co" rel="external nofollow noopener" target="_blank">a-compress.co</a></p>
            </li>
            <li>
            <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
            </ul>
            <h1>Your Order Cancellation Rights</h1>
            <p>You are entitled to cancel Your Order within 45 days without giving any reason for doing so.</p>
            <p>The deadline for cancelling an Order is 45 days from the date on which You were charged the first payment of the subscription, not counting free trials.</p>
            <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
            <ul>
            <li>By email: pyron@holdmy.coffee</li>
            </ul>
            <p>We will reimburse You no later than 14 days from the day on which We receive your cancellation notice. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about our Refunds Policy, please contact us:</p>
            <ul>
            <li>By email: pyron@holdmy.coffee</li>
            </ul>
            </div>
            <BottomBar/>
            </div>
            
          </Content>
        </PageLayout>
    )
  }
  
  export default RefundsPolicy;
  